import React from 'react';
import classnames from 'classnames';
import { ApiTypesV1GroupResponse, GroupWrapper } from '@wix/social-groups-api';

import { GroupPrivacyLevel } from './GroupPrivacy';
import { classes, st } from './GroupCardMeta.st.css';
import { GROUP_TITLE_LINK } from './dataHooks';
import { isUndefinedOrTrue } from '../../../utils/utils';
import { MembersLabelWithCount } from '../../MembersLabel/MembersLabelWithCount';
import { RoleBadges } from '../../RoleBadges';
import {
  classes as badgesClasses,
  st as badgesSt,
} from '../../RoleBadges/GroupsListBadge.st.css';
import { useSettings } from '@wix/tpa-settings/react';
import { settingsParams } from '../../../settings/groups-list/settings';
import { widgetSettingsParams } from '../../../settings/groups-list/widgetSettings';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { PluralText } from 'common/components/PluralText';

interface GroupCardMetaProps {
  group: ApiTypesV1GroupResponse;
  groupUrl: string;
  className?: string;
  withAdminBadge?: boolean;
  alwaysShowMemberRequests?: boolean;
  truncate?: boolean;
}

export const GroupCardMeta: React.FC<GroupCardMetaProps> = ({
  group,
  groupUrl,
  className,
  withAdminBadge,
  alwaysShowMemberRequests,
  truncate,
}) => {
  const { relationship, roles } = group;
  const groupWrapper = new GroupWrapper(group);
  const { isMobile } = useEnvironment();
  const memberCount = groupWrapper.getMembersCount();
  const pendingMembersCount = groupWrapper.getPendingMembersCount();
  const membersName = groupWrapper.getMembersName();
  const privacyLevel = groupWrapper.getPrivacy();

  const settings = useSettings();

  const showMembersCount = settings.get(settingsParams.showMemberCount);
  // TODO: widgetSettingsParams?
  const groupType = isUndefinedOrTrue(
    settings.get(widgetSettingsParams.showGroupType),
  );

  const { t } = useTranslation();
  return (
    <div
      className={st(
        classes.root,
        {
          mobile: isMobile,
          truncate: !!truncate,
        },
        classnames(classes.meta, className),
      )}
    >
      <div className={classes.header}>{renderTitleLink()}</div>
      <p className={classes.details}>{renderMembersLabelWithCount()}</p>
    </div>
  );

  function renderMembersLabelWithCount() {
    if (alwaysShowMemberRequests && pendingMembersCount) {
      return renderPendingMembersCount();
    }
    return (
      <>
        {groupType && <GroupPrivacyLevel t={t} privacyLevel={privacyLevel} />}
        {showMembersCount && groupType && <span>&nbsp;&middot;&nbsp;</span>}
        {showMembersCount ? renderMembersCount() : null}
        {showMembersCount && pendingMembersCount ? (
          <>
            <span>&nbsp;&middot;&nbsp;</span>
            {renderPendingMembersCount()}
          </>
        ) : null}
      </>
    );
  }

  function renderMembersCount() {
    return (
      <MembersLabelWithCount
        groupMemberLabel={membersName}
        count={memberCount}
      />
    );
  }

  function renderPendingMembersCount() {
    return (
      <span className={classes.pendingMembers}>
        <PluralText
          translationKey="groups-web.group-list.pending-members.count"
          count={pendingMembersCount}
        />
      </span>
    );
  }
  function renderTitleLink() {
    return (
      <>
        <a
          data-hook={GROUP_TITLE_LINK}
          className={classes.link}
          href={groupUrl}
          data-bi-origin="group_title"
        >
          {group.details?.title}
        </a>
        {withAdminBadge && (
          <RoleBadges
            relationship={relationship!}
            roles={roles!}
            getBadgeClassName={(isAdmin) =>
              badgesSt(badgesClasses.badge, { isAdmin })
            }
          />
        )}
      </>
    );
  }
};
