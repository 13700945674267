import React from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import { ApiTypesV1GroupResponse } from '@wix/social-groups-api';

import { Header } from 'common/components/Text/Header';
import { settingsParams } from 'common/settings/groups-list/settings';

import { GroupCard } from '../GroupCard/GroupCard';

import { classes } from './Groups.st.css';

type GroupsProps = {
  title: string;
  groups: ApiTypesV1GroupResponse[];
  goToGroup?(groupId: string): void;
};

export const Groups: React.FC<GroupsProps> = (props) => {
  const settings = useSettings();
  const shouldShowImage = settings.get(settingsParams.showImage);

  return (
    <div>
      <Header
        textAlign={settings.get(settingsParams.headerAlignment)}
        className={classes.header}
      >
        {props.title}
      </Header>
      <div>
        {props.groups.map((group) => (
          <GroupCard
            group={group}
            goToGroup={props.goToGroup}
            withImage={shouldShowImage}
          />
        ))}
      </div>
    </div>
  );
};
