import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { useGroupsSettings } from 'common/hooks/settings/useGroupsSettings';
import { LayoutType } from 'common/settings/groups-list/settingsConstants';

import { useShadowStyles } from '../hooks/useShadowStyles';

import { GroupsSection, GroupsSectionRenderProps } from './GroupsSection';
import { ListLayout } from './ListLayout/ListLayout';
import { SideBarLayout } from './SideBarLayout/SideBarLayout';

import { classes, st } from './Groups.st.css';

export const Groups: React.FC = () => {
  const groupsSettings = useGroupsSettings();
  const { isMobile } = useEnvironment();
  const shadowStyles = useShadowStyles();

  return (
    <div
      data-hook="groups"
      className={st(classes.root, { mobile: isMobile })}
      ref={shadowStyles.onRefChange}
    >
      <GroupsSection>{renderLayout}</GroupsSection>
    </div>
  );

  function renderLayout({ openCreateGroupModal }: GroupsSectionRenderProps) {
    switch (groupsSettings.groupListLayout) {
      case LayoutType.sideBar:
        return <SideBarLayout onCreateGroupClick={openCreateGroupModal} />;
      default:
        return <ListLayout onCreateGroupClick={openCreateGroupModal} />;
    }
  }
};
